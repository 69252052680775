<template>
  <v-dialog
    v-model="dialog"
    :content-class="isDarkMode ? 'dark-mode' : ''"
    persistent
    width="96vw"
  >
    <div class="wrapper-1 bg-white ma-0">
      <v-card-title
        class="text-h5 bg-grey title-borderd-flex justify-space-between"
        tabindex="0"
      >
        <b>{{ $t('Filtering Report') }}</b>

        <img
          :src="`${selectedHost}/api/images/${loggedUser.organizationId}/logo`"
          alt="host logo"
          class="h-35"
        />
      </v-card-title>

      <div class="d-flex flex-column">
        <!--THE REPORT-->

        <div
          v-html="computedHTML"
          class="wrapper-1 ma-2 report-wrapper fs-1r"
          tabindex="0"
        ></div>

        <v-divider></v-divider>
        <div class="d-flex justify-space-between align-center pa-2">
          <!--DOWNLOAD THE HTML REPORT-->
          <Button
            :text="$t('Download')"
            width="120"
            :clickAction="downloadReportClicked"
          />

          <Button
            :text="$t('Close')"
            btnType="grey"
            width="70"
            :clickAction="closeHtmlDialog"
          ></Button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { selectedHost } from '@/config'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Function to download report
import { DownloadReport } from '@/components/WebsiteInterface/Websiteinterface'

export default {
  name: 'HtmlReportDialog',
  data() {
    return {
      dialog: true,
      selectedHost,
    }
  },
  props: {
    sessionRes: Object,
    closeHtmlDialog: Function,
  },
  components: { Button },
  computed: {
    ...mapGetters(['loggedUser', 'isDarkMode']),
    computedHTML() {
      return this.sessionRes.htmlReport.summary.replaceAll(
        '<label',
        '<label style="word-break: break-all;"'
      )
    },
  },
  methods: {
    downloadReportClicked() {
      DownloadReport(this.sessionRes.htmlReport)
    },
  },
}
</script>

<style scoped src="./HtmlReportDialog.css"></style>
