<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      :content-class="isDarkMode ? 'dark-mode' : ''"
    >
      <!--TITLE-->
      <v-card class="wrapper-1">
        <v-card-title
          :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
          class="text-center bg-grey pa-2 fs-1-5r"
        >
          <span>{{ $t('Enter Your Password') }}</span>
        </v-card-title>
        <!--MAIN-->
        <div
          class="mx-4 my-1"
          :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr']"
        >
          <!--MESSAGE-->
          <span class="my-2 mt-5 fs-1-12r">{{
            $t(
              'Due to security reasons, before downloading files please type your login password again'
            )
          }}</span>
          <!--INPUT TO PASSWORD-->
          <v-text-field
            :label="$t('Password')"
            v-model="password"
            color="primary"
            hide-details
            :type="showPassword ? 'text' : 'password'"
          >
            <!--WEBSITE-->
            <template v-if="$vuetify.breakpoint.width >= 769" v-slot:append>
              <v-icon
                @mousedown="showPassword = true"
                @mouseup="showPassword = false"
                class="pointer"
                >mdi-eye{{ showPassword ? '-off' : '' }}</v-icon
              >
            </template>
            <!--MOBILE-->
            <template v-else v-slot:append>
              <v-icon @click="showPassword = !showPassword" class="pointer"
                >mdi-eye{{ showPassword ? '-off' : '' }}</v-icon
              >
            </template>
          </v-text-field>
        </div>

        <v-divider class="my-2"></v-divider>

        <!--BUTTONS TO CONTINUE OR CANCEL-->
        <v-card-actions :class="[language === 'he' ? 'dir-rtl' : 'dir-ltr', 'd-flex', 'justify-space-between']">
          <v-spacer></v-spacer>
          <!--CONTINUE-->
          <Button
          :clickAction="downloadClicked"
          :disabled="password.length < 6"
          :text="$t('Download')"
          width="120"
          class="mx-1"
          />
          <!--CANCEL-->
          <Button
            :text="$t('Cancel')"
            btnType="grey"
            :clickAction="() => setIsShowPasswordScreen(false)"
            class="mx-1"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { api } from '@/config'

//Childrens
import Button from '@/components/BaseComponents/Button/Button.vue'

export default {
  name: 'InsertPassword',
  data() {
    return { dialog: true, showPassword: false, password: '' }
  },
  props: {
    downloadFilesClicked: Function,
    setIsShowPasswordScreen: Function,
  },
  components: { Button },

  computed: {
    ...mapGetters(['loggedUser', 'isDarkMode', 'language']),
  },
  methods: {
    ...mapMutations(['SET_PASS', 'SET_PROCESSING']),
    async downloadClicked() {
      try {
        this.SET_PROCESSING(true)
        //check if the password that user inserted is okay
        const payload = {
          username: this.loggedUser.email,
          password: this.password,
        }
        const res = await api.post('auth/ispasswordvalid', payload)

        if (res.status !== 200) throw Error
        //set the user password
        this.SET_PASS(this.password)

        //download the selected files
        this.downloadFilesClicked()
      } catch (e) {
        console.log(e)
      } finally {
        this.SET_PROCESSING(false)
      }
    },
  },
}
</script>
