import { api, selectedHost } from '@/config'
import store from '@/store'
import { alertDialog, confirmDialog } from '@/utils'

//=====================================
//======GET DIRECROTIES FUNCTIONS======
//=====================================
async function getBaseDirectory(vue) {
  store.commit('SET_PROCESSING', true)
  const singleFolderRes = await api.get(`tree/root`)

  //if there are no files or directories
  if (
    (singleFolderRes.data && ( !singleFolderRes.data.children || !singleFolderRes.data.children.length)) ||
    singleFolderRes.status === 204
  ) {
    store.commit('SET_PROCESSING', false)

    await alertDialog(
      vue,
      vue.$t(`You don't have any files or folders in your main folder.`)
    )
  } else if (singleFolderRes.status !== 200) throw Error
console.log(singleFolderRes)
  vue.shownDirectory = singleFolderRes.data
  store.commit('SET_PROCESSING', false)
}

export async function getStartFileDirPoint(vue, type) {
  //this function gets the data that need to be loaded when the downloading screen first show up
  try {
    store.commit('SET_PROCESSING', true)

    //this is if the user came from email to see his files
    if (vue.userCameFromEmailPath) {
      if (vue.userCameFromEmailPath === 'root') {
        vue.userCameFromEmailPath = ''
      }
      const singleFolderRes = await api.get(
        `tree/root/${vue.userCameFromEmailPath}`
      )

      //if directory was not found
      if (singleFolderRes.status === 204) {
        store.commit('SET_PROCESSING', false)

        await alertDialog(vue, `Directory was not found`)

        //get the base directory if the files where not found
        getBaseDirectory(vue)
      }

      //if server error
      else if (singleFolderRes.status !== 200) throw Error
      //if everything is okay
      else vue.shownDirectory = singleFolderRes.data
    }

    //this show the base root of the user's tree
    else getBaseDirectory(vue)

    //this get the base tree of the directories of the user and is not shown on mobile
    if (type === 'mobile') return

    const filesTreeRes = await api.get('tree')
    if (filesTreeRes.status !== 200) throw Error
    vue.directoriesTree = filesTreeRes.data
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

export async function getDirTree(vue) {
  //this function get the directories tree of the user
  try {
    store.commit('SET_PROCESSING', true)

    const res = await api.get('tree')

    console.log(res)
    if (res.status !== 200) throw Error
    vue.directoriesTree = res.data
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

export async function getSingleDirFromPathArr(vue, pathArr) {
  //this function take pathes arr and ask from the server the directory
  try {
    const serverPathesNames = pathArr.map(el => el.serverName).join('/')

    store.commit('SET_PROCESSING', true)

    const singleFolderRes = await api.get(`tree/root/${serverPathesNames}`)
    if (singleFolderRes.status !== 200) throw Error

    //set the vue shown directory as the data
    vue.shownDirectory = singleFolderRes.data
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

export async function getSingleDirFromPathNav(vue, pathObj) {
  //this function gets the name of the wanted directory and find it in the shown directory object
  //then it asks the server for the directory

  try {
    store.commit('SET_PROCESSING', true)
    const serverPathDirNames = []
    for (let path of vue.shownDirectory.path) {
      serverPathDirNames.push(path.serverName)
      if (path.serverName === pathObj.serverName) break
    }

    const pathesStr = serverPathDirNames.join('/')
    const singleFolderRes = await api.get(`tree/root/${pathesStr}`)

    if (singleFolderRes.status !== 200) throw Error

    //set the vue shown directory as the data
    vue.shownDirectory = singleFolderRes.data
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

export async function getOneDirBack(vue) {
  //this runs when the user click the back button on the directories tree
  if (!vue.shownDirectory.path.length) return
  try {
    store.commit('SET_PROCESSING', true)
    const serverPathesNames = vue.shownDirectory.path
      .slice(0, -1)
      .map(el => el.serverName)
      .join('/')

    const singleFolderRes = await api.get(`tree/root/${serverPathesNames}`)
    if (singleFolderRes.status !== 200) throw Error

    //set the vue shown directory as the data
    vue.shownDirectory = singleFolderRes.data
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

export async function getAllFiles(vue) {
  //this function will get all the files without the heirarchey, just long list
  try {
    store.commit('SET_PROCESSING', true)
    const res = await api.get('tree/allfiles')

    if (res.status !== 200) throw Error
    vue.shownDirectory = {
      name: 'All files',
      children: res.data,
      path: [],
      size: 0,
    }
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

//============================
//======REPORT FUNCTIONS======
//============================
export async function getFilteringReport(vue) {
  //this will run when user asks for report
  try {
    store.commit('SET_PROCESSING', true)
    const selectedFiles = []
    const selectedDirectories = []

    vue.selectedDirFiles.forEach(el => {
      //if this is a file
      if (el.repoId)
        return selectedFiles.push(el.path.map(el => el.serverName).join('/'))

      //if the item is a directory
      const dirPath = el.path.map(el => el.serverName).join('/')
      selectedDirectories.push(dirPath)
    })

    const payload = {
      files: selectedFiles,
      directories: selectedDirectories,
    }

    const res = await api.post(`reports`, payload)

    if (res.status !== 200) throw Error

    vue.report = res.data

    vue.isShowReportDialog = true
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

//============================
//======DELETE FUNCTIONS======
//============================
export async function deleteDirFiles(vue) {
  //this function will run when you delete files
  const text =
    vue.$t('Are you sure you want to delete these files and/or folders') + '?'

  const thenFunc = async e => {
    try {
      store.commit('SET_PROCESSING', true)
      //this will run if it is not from all files
      const paths = vue.selectedDirFiles.map(dirFile =>
        dirFile.path.map(p => p.serverName).join('/')
      )

      //this will store all the files at the server and save some guid
      const storeRes = await api.post('filter/storepathes', { paths })
      if (storeRes.status !== 200) throw Error

      //delete the files that the server saved
      const deleteRes = await api.delete(`filter/deletefiles/${storeRes.data}`)
      if (deleteRes.status !== 200) throw Error
      getSingleDirFromPathArr(vue, vue.lastSeenDirPath)
    } catch (e) {
      console.log(e)
    } finally {
      vue.selectedDirFiles = []
      store.commit('SET_PROCESSING', false)
    }
  }

  confirmDialog(vue, text, vue.$t('Delete'), vue.$t('Cancel'), thenFunc)
}

//============================
//=====DOWNLOAD FUNCTIONS=====
//============================
export async function downloadDirFiles(vue) {
  //download directories and/or files that selected
  try {
    store.commit('SET_PROCESSING', true)
    console.log(vue.selectedDirFiles)
    //get all the files that selected pathes
    let paths = vue.selectedDirFiles.map(dirFile =>
      dirFile.path.map(p => p.serverName).join('/')
    )
    const data = getParentAndShortPaths(JSON.parse(JSON.stringify(paths)))
    if (data.shortPaths.length === 1 && data.shortPaths[0].replace(/\/$/, "") === data.parent.replace(/\/$/, "")){
      data.shortPaths = []
    }
    const payload = {
      password: store.getters.usrPass,
      paths: data.shortPaths,
      parent: data.parent,
    }

    //this will store all the files at the server and save some guid
    const res = await api.post('filter/storepathes', payload)
    if (res.status !== 200) throw Error
    //make specific url of the files that the server saved
    const href = `${selectedHost}/api/filter/download/${res.data}?bearer=${store.getters.loggedUser.token}`

    //make a with href and click him
    const a = Object.assign(document.createElement('a'), {
      href,
      style: 'display: none',
      download: `Download files`,
    })

    document.body.appendChild(a)
    a.click()
    a.remove()
  } catch (e) {
    console.log(e)
  } finally {
    store.commit('SET_PROCESSING', false)
  }
}

function getParentAndShortPaths(paths) {
  if (!paths || paths.length === 0) return paths
  let commonParts = paths[0].split(/[\/\\]/)
  for (let path of paths) {
    let parts = path.split(/[\/\\]/)
    for (let i = 0; i < commonParts.length; i++) {
      if (i >= parts.length || commonParts[i] !== parts[i]) {
        commonParts = commonParts.slice(0, i)
        break
      }
    }
  }
  const parent = commonParts.join('/') + '/'
  const shortPaths = paths.map(p =>
    p.startsWith(parent) ? p.slice(parent.length) : p
  )
  console.log(parent)
  console.log(shortPaths)
  return {
    parent,
    shortPaths,
  }
}

// function getCommonParent(paths) {
//   console.log(paths)
//   if (!paths || paths.length === 0) return paths
//   let commonParts = paths[0].split(/[\/\\]/)

//   // Find the common prefix by comparing each path
//   for (let path of paths) {
//     let parts = path.split(/[\/\\]/)

//     for (let i = 0; i < commonParts.length; i++) {
//       if (i >= parts.length || commonParts[i] !== parts[i]) {
//         commonParts = commonParts.slice(0, i)
//         break
//       }
//     }
//   }

//   // The last common directory
//   const commonPath = commonParts.join('/') + '/'
//   console.log('commonPath: ' + commonPath)

//   // Remove all the common path except the last common directory
//   return commonPath
// }
