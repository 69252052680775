<template>
  <v-dialog
    v-model="dialog"
    persistent
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="fit-content"
  >
    <div class="bg-white w-350">
      <div
        class="text-h5 bg-grey font-weight-medium px-3"
      >
        
        
        <div style="height: 9px; display: flex; justify-content: end;">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                dense
                v-on="on"
                @click="closeDialog"
                large
                color="black"
                :style="language === 'he' ? 'font-size: 20px; transform: translate(-8px, 11px);' : 'font-size: 20px; transform: translate(8px, 11px);'"
                >mdi-close-circle-outline</v-icon
              >
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>
        <div class="d-flex justify-center pb-2">
          {{ $t('Permanent Uploading Link') }}
        </div>
      </div>
    </div>
    <div class="bg-white d-flex justify-space-between pa-2">
      <Button :text="$t('Copy Link')" :clickAction="copyLink" class="mx-4" />
      <Button
        :text="$t('Change Link')"
        :clickAction="renewLinkClicked"
        btnType="grey"
        class="mx-4"
      />
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import Language from '@/components/PageParts/Header/Childrens/Language/Language.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PermanentLinkDialog',
  data: () => ({
    dialog: true,
  }),
  components: {
    Button,
  },
  props: {
    closeDialog: Function,
    renewLinkClicked: Function,
    permanentLink: String,
  },
  computed: {
    ...mapGetters(['isDarkMode', 'language']),
  },
  methods: {
    ...mapMutations(['SET_NOTIFICATION']),
    async copyLink() {
      await navigator.clipboard.writeText(this.permanentLink)
      this.SET_NOTIFICATION({
        text: this.$t('Link copied to clipboard'),
        type: 'success',
      })
    },
  },
  created() {
    console.log(this.permanentLink)
  },
}
</script>
<style scoped>
.close-btn {
  font-size: 24px !important; 
  position: relative !important; 
  top: -6px !important; 
}
.close-btn-en {
  right: -7px !important; 
}
.close-btn-he {
  right: 7px !important; 
}
</style>
