<template>
  <div class="d-flex mb-5">
    <div class="text-field-container">
      <!--TITLE -->
      <span
        class="small-title"
        :class="[status !== 0 && !isGlobal && 'low-op-disabeld']"
        >Background Image
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small>mdi-information-outline</v-icon>
          </template>
          <span> Recommended: 1920x1080</span>
        </v-tooltip></span
      >
      <!--SHOWN IMAGE-->
      <v-img
        ref="logoImage"
        :src="computedBackgroundUrl"
        alt="Background logo"
        class="shown-logo"
      />
      <!--INPUT FO BACKGROUND -->
      <input
        style="display: none"
        type="file"
        ref="avatarBg"
        dense
        accept="image/png, image/jpeg, image/bmp"
        @change="e => updateBackgroundPreview(e)"
      />
      <!--CHANGE BACKGROUND-->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-hover v-slot="{ hover }">
            <v-icon
              :color="hover ? 'green' : 'grey'"
              v-on="on"
              class="my-2 mr-2"
              @click="backgroundFileInputClicked"
              :disabled="status !== 0 && !isGlobal"
              >mdi-upload</v-icon
            >
          </v-hover>
        </template>
        <span>Select background image</span>
      </v-tooltip>

      <!--DELETE BACKGROUND-->
      <v-tooltip v-if="!isGlobal && actionType === 'edit'" bottom>
        <template v-slot:activator="{ on }">
          <v-hover v-slot="{ hover }">
            <v-icon
              :color="hover ? 'red' : 'grey'"
              v-on="on"
              class="my-2"
              @click="deleteBackground"
              :disabled="status !== 0 && !isGlobal"
              >mdi-delete</v-icon
            >
          </v-hover>
        </template>
        <span>Delete background image</span>
      </v-tooltip>
    </div>
    <!--CHECKBOX-->
    <div v-if="!isGlobal" class="d-flex align-center">
      <v-checkbox
        v-model="organization.theme.backgroundInterface"
        label="Website"
        :disabled="status !== 0"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { selectedHost } from '@/config'
import { mapMutations, mapGetters } from 'vuex'
import { setTimeout } from 'timers'
import { alertDialog } from '@/utils'

export default {
  name: 'BackgroundImage',
  data() {
    return {
      backgroundUrl: null,
      readerResult: null,
    }
  },
  props: {
    organization: Object,
    status: Number,
    isGlobal: Boolean,
    images: Object,
    actionType: String,
  },
  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser', 'themeSettings']),
    computedBackgroundUrl() {
      if (this.readerResult) return this.readerResult
      const time = Date.now()
      return `${this.backgroundUrl}?${time}`
    },
  },
  methods: {
    ...mapMutations(['SET_NOTIFICATION', 'SET_PROCESSING']),
    backgroundFileInputClicked() {
      this.$refs.avatarBg.value = null
      this.$refs.avatarBg.click()
    },
    deleteBackground() {
      this.backgroundUrl = `${selectedHost}/api/images/0/background`
      this.images.background = 'delete'
    },
    async updateBackgroundPreview(e) {
      const background = e.target.files[0]

      if (
        !background.type.includes(
          'image/' || background.type.includes('svg+xml')
        )
      ) {
        return alertDialog(this, 'File type is not supported for logo')
      }

      const reader = new FileReader()
      reader.readAsDataURL(background)

      //save the photo in varible and add it when save
      this.images.background = background

      //i dont know why but the timeout is needed to show the logo
      setTimeout(() => {
        this.readerResult = reader.result
      }, 100)
    },
  },
  created() {
    //if this is global organization
    if (this.isGlobal) {
      this.backgroundUrl = `${selectedHost}/api/images/0/background`
    }
    //if this is regular organization
    else {
      this.backgroundUrl = `${selectedHost}/api/images/${this.currentOrganization.organizationId}/background`
    }
  },
}
</script>

<style scoped src="../ThemeSettingsChildren.css"></style>
