<template>
  <v-dialog
    v-model="dialog"
    persistent
    :content-class="isDarkMode ? 'dark-mode' : ''"
    width="500"
  >
    <v-card class="bg-white">
      <v-card-title
        class="text-h5 red white--text"
        :class="[isDarkMode && 'darken-3']"
      >
        {{ computedErrorTitle }}
        <v-spacer></v-spacer>
        <v-icon color="white" large>mdi-alert</v-icon>
      </v-card-title>

      <v-card-text
        class="fs-1-25r my-3 font-weight-regular"
        :aria-label="`Error, ${errorNotification}`"
        tabindex="0"
      >
        {{ computedErrorNotification }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="ma-0 d-flex justify-end">
        <v-spacer></v-spacer>
        <Button :text="$t('Close')" btnType="grey" :clickAction="closeDialog" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ServerErrorDialog',
  data() {
    return {
      dialog: true,
    }
  },
  props: {
    errorNotification: String,
    errorTitle: String,
    closeDialog: Function,
  },

  components: {
    Button,
  },

  computed: {
    ...mapGetters(['isDarkMode']),
    computedErrorTitle() {
      if (this.errorTitle) return this.$t(this.errorTitle)
      return this.$t('Error')
    },
    computedErrorNotification() {
      if (this.errorNotification === 'Username or password is incorrect')
        return this.$t('Username or password is incorrect')
       else if (this.errorNotification === 'Files were already deleted')
        return this.$t('Files were already deleted')

      return this.errorNotification
    },
  },
}
</script>
