<template>
  <v-dialog
    v-model="showCreatePolicy"
    persistent
    width="auto !important"
    class="wrapper-1 bg-white pos-relative"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="text-center bg-grey mb-4">
        Create new policy
      </v-card-title>
      <v-card-text class="pb-1">
        <v-text-field
          v-model="policy"
          lazy
          :rules="newPolicyRules"
          class="ma-auto"
          label="Policy Name"
          type="text"
          dense
        ></v-text-field>
      </v-card-text>
      <div class="d-flex justify-end pa-2">
        <Button
          :clickAction="createNewPolicyClicked"
          text="Create"
          width="100"
          class="mx-2"
          :disabled="isCreateRenameBtnsDisabled"
        >
        </Button>
        <Button
          :clickAction="cancelCreatePolicyClicked"
          text="Cancel"
          btnType="grey"
          width="100"
          class="mx-2"
        >
        </Button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { isFolderNameValid } from '@/utils'

//Components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Component files
import { newPolicyRules } from '../Policies.js'

export default {
  name: 'AddNewPolicy',
  data() {
    return {
      policy: '',
    }
  },
  props: { showCreatePolicy: Boolean },
  emits: ['toggle-is-show-create-policy'],
  components: { Button },
  computed: {
    ...mapGetters(['policies', 'isDarkMode']),
    newPolicyRules,
    isCreateRenameBtnsDisabled() {
      if (!this.policy) return true
      else if (!isFolderNameValid(this.policy)) return true
      else if (this.policies.includes(this.policy)) return true
      return false
    },
  },
  methods: {
    createNewPolicyClicked() {
      this.$router.push(`policies/${this.policy}`)
    },
    cancelCreatePolicyClicked() {
      this.policy = ''
      this.$emit('toggle-is-show-create-policy', false)
    },
  },
}
</script>
