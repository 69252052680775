<template>
  <div class="d-flex fill-height fill-width">
    <v-tab-item
      v-if="shownDirectory"
      value="downloading"
      class="fill-width fill-height d-flex download-data"
    >
      <!--LEFT SIDE - DIRECTORIES TREE-->
      <div
        class="wrapper-1 bg-white ma-1"
        :class="[isDirTreeOpen ? 'dir-tree-big' : 'dir-tree-small']"
        tabindex="-1"
      >
        <!--ARROW TO CLOSE/OPEN THE DIRECTORIES TREE-->
        <div class="wrapper-1 h-50 mx-2 mt-2 pa-2">
          <v-icon class="float-right mt-1" @click="toggleDirTree" tabindex="-1"
            >mdi-arrow-{{ computedArrow }}</v-icon
          >
        </div>

        <!--THE TREE OF THE DIRECTORIES-->
        <DirectoriesTree
          v-if="isDirTreeOpen"
          :directoriesTree="directoriesTree"
          :changeShownDirClicked="changeShownDirClicked"
          :shownDirectory="shownDirectory"
          type="desktop"
        />
      </div>

      <!--RIGHT SIDE - DIRECTORY AND DATA-->
      <div
        v-if="shownDirectory"
        class="wrapper-1 bg-white pa-2 ma-1"
        :class="[isDirTreeOpen ? 'dir-table-small' : 'dir-table-big']"
      >
        <!--TOP LINE - DIRECTORY PATH AND ACTIONS IN FOLDER-->
        <DirectoryHeader
          :shownDirectory="shownDirectory"
          :changeShownDirClicked="changeShownDirClicked"
          :setDirSearch="v => (dirSearch = v)"
        />

        <!--SHOWN DIRECTORY-->
        <DirectoryDownloadTable
          :shownDirectory="shownDirectory"
          :changeShownDirClicked="changeShownDirClicked"
          :setSelectedDirFiles="v => (selectedDirFiles = v)"
          :dirSearch="dirSearch"
          :selectedDirFiles="selectedDirFiles"
          type="desktop"
        />

        <!--DIRECTORY DATA-->
        <DirectoryData :shownDirectory="shownDirectory" />

        <!--DIRECTORY ACTIONS-->
        <FileDirActions
          :shownDirectory="shownDirectory"
          :selectedDirFiles="selectedDirFiles"
          :showReportClicked="showReportClicked"
          :deleteFilesClicked="deleteFilesClicked"
          :downloadFilesClicked="downloadFilesClicked"
        />
      </div>

      <!--INSERT PASSWORD DIALOG-->
      <InsertPassword
        v-if="isShowPasswordScreen"
        :setIsShowPasswordScreen="v => (isShowPasswordScreen = v)"
        :downloadFilesClicked="downloadFilesClicked"
      />

      <!--REPORT DIALOG FOR DIRECTORIES AND FILES-->
      <DirFilesTableReportDialog
        v-if="isShowReportDialog"
        :closeReportDialog="closeReportDialog"
        :report="report"
      />
    </v-tab-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

//Base components
import DirectoriesTree from '@/components/WebsiteInterface/CommonComponents/DirectoriesTree/DirectoriesTree.vue'
import DirectoryData from '@/components/WebsiteInterface/CommonComponents/DirectoryData/DirectoryData.vue'
import InsertPassword from '@/components/WebsiteInterface/CommonComponents/InsertPassword/InsertPassword.vue'
import FileDirActions from '@/components/WebsiteInterface/CommonComponents/FileDirActions/FileDirActions.vue'
import DirectoryDownloadTable from '@/components/WebsiteInterface/CommonComponents/DirectoryDownloadTable/DirectoryDownloadTable.vue'
import DirFilesTableReportDialog from '@/components/WebsiteInterface/CommonComponents/DirFilesTableReportDialog/DirFilesTableReportDialog.vue'

//Children
import DirectoryHeader from './Children/DirectoryHeader/DirectoryHeader.vue'

//Downloading functions
import {
  //get directoreis
  getStartFileDirPoint,
  getSingleDirFromPathArr,
  getSingleDirFromPathNav,
  getOneDirBack,
  getAllFiles,
  //get actual data
  getFilteringReport,
  deleteDirFiles,
  downloadDirFiles,
} from '@/components/WebsiteInterface/downloadingFunctions'

export default {
  name: 'DownloadDesktop',
  data() {
    return {
      directoriesTree: {},
      shownDirectory: null,
      isDirTreeOpen: false,
      dirSearch: null,
      selectedDirFiles: [],
      isShowPasswordScreen: false,
      lastSeenDirPath: [],
      report: {
        files: [],
        directories: [],
      },
      isShowReportDialog: false,
    }
  },
  props: {
    userCameFromEmailPath: String,
    setTab: Function,
  },

  components: {
    DirectoriesTree,
    DirectoryHeader,
    DirectoryDownloadTable,
    FileDirActions,
    DirectoryData,
    InsertPassword,
    DirFilesTableReportDialog,
  },
  watch: {
    shownDirectory: {
      handler: function (n) {
        console.log(n)
        if (n && n.name !== 'All files') {
          this.lastSeenDirPath = n.path
        }
        this.selectedDirFiles = []
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(['usrPass', 'loggedUser', 'language']),
    computedArrow() {
      if (this.language === 'he') return this.isDirTreeOpen ? 'right' : 'left'
      return this.isDirTreeOpen ? 'left' : 'right'
    },
  },
  methods: {
    toggleDirTree() {
      this.isDirTreeOpen = !this.isDirTreeOpen
    },
    changeShownDirClicked(dirPath, type) {
      //if the user clicked the navigation route
      if (type === 'navigation') getSingleDirFromPathNav(this, dirPath)
      //if the user clicked the directories tree
      else if (type === 'dirTree') getSingleDirFromPathArr(this, dirPath)
      //if the user clicked the directory from the table row
      else if (type === 'tableRow') getSingleDirFromPathArr(this, dirPath)
      //if the user clicked the back on directory button
      else if (type === 'backButton') getOneDirBack(this)
      //if the user asked for all files
      else if (type === 'allFiles') getAllFiles(this)
      //if the user asked to start from the begining
      else if (type === 'start') getStartFileDirPoint(this, 'tablet')
      //if the user was in all files and returned to directory
      if (type === 'backButtonFromAllFiles')
        getSingleDirFromPathArr(this, this.lastSeenDirPath)
    },

    closeReportDialog() {
      this.isShowReportDialog = false
      this.report = {
        files: [],
        directories: [],
      }
    },

    showReportClicked() {
      getFilteringReport(this)
    },

    deleteFilesClicked() {
      deleteDirFiles(this)
    },
    downloadFilesClicked() {
      //if the user refreshsed and he dosen't hav epassord and also he is not casual
      if (!this.usrPass && this.loggedUser.role !== 'Casual')
        return (this.isShowPasswordScreen = true)

      //if the user have password
      this.isShowPasswordScreen = false
      downloadDirFiles(this)
    },
  },

  async created() {
    await getStartFileDirPoint(this, 'desktop')
  },
}
</script>

<style scoped src="./DownloadDesktop.css"></style>
