<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :options="localOptions"
    :items-per-page="itemsPerPage"
    :hide-default-footer="hideDefaultFooter"
    @update:options="optionsUpdated"
    class="wrapper-1 ma-1 custom-table"
    style="overflow: revert"
    :height="tableWrapperHeight - 70"
    must-sort
    ref="table"
    :noDataText="noDataText"
  >
    <template v-slot:body="{ items, headers }">
      <tbody class="fill-height fill-width overflow-auto">
        <tr
          v-for="(item, i) of items"
          :key="i"
          @click="rowClickAction ? rowClickAction(item) : false"
          :class="[
            rowClickAction && 'pointer',
            item.status === 'Blocked' && 'semi-opacity',
            markedLinesArr.includes(item.logId) && 'light-blue-bg',
          ]"
        >
          <td
            v-for="td of headers"
            :style="{ width: td.width, 'text-align': td.align }"
          >
            <!--CHECKBOX TO MARK THE LINE-->
            <div v-if="td.value === 'checkbox'">
              <v-simple-checkbox
                @click="() => checkboxClicked(item.logId)"
                :value="markedLinesArr.includes(item.logId)"
                color="primary"
              ></v-simple-checkbox>
            </div>
            <!--REPORT SUMMARY FOR HISTORY SESSIONS-->
            <div
              v-else-if="td.value === 'reportSummary'"
              class="d-flex justify-center"
            >
              <div v-for="result of summaryBuilder" class="d-flex mx-1 w-80">
                <!--IMAGE-->
                <img :src="result.img" class="mx-1 h-20" />
                <!--NUMBER-->
                <span class="mx-1"
                  ><b>{{ item.reportSummary[result.type] }}</b></span
                >
              </div>
            </div>

            <!--IF IT IS MORE DATA ABOUT SESSION-->
            <div v-else-if="td.value === 'moreDataOfLog'">
              <!--MORE INFO ABOUT THE SESSION-->
              <Button
                v-if="item[td.value].operationId"
                :clickAction="() => setOpId(item[td.value].operationId)"
                btnSize="small"
                btnType="grey"
                height="20px"
                text="Session"
                class="my-2"
              />

              <!--MORE INFO ABOUT THE FILE-->
              <Button
                v-if="item[td.value].fileGuid"
                :clickAction="() => setFileGuid(item[td.value].fileGuid)"
                btnSize="small"
                height="20px"
                btnType="grey"
                class="my-2"
                text="File"
              />
            </div>

            <!--IS REQUIRE HTML-->
            <span v-else-if="td.isHtml" v-html="item[td.value]"> </span>

            <div v-else-if="td.icon" class="d-flex justify-center">
              <v-icon large>{{ td.icon }}</v-icon>
            </div>

            <!--REGULAR SITUATIONS-->
            <span v-else>
              {{ td.value ? item[td.value] : '' }}
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import intact from '@/assets/intact.svg'
import modified from '@/assets/modified.svg'
import partial from '@/assets/partial.svg'
import blocked from '@/assets/blocked.svg'
import Button from '../Button/Button.vue'

const summaryBuilder = [
  { type: 'intact', img: intact, label: 'Intact' },
  { type: 'modified', img: modified, label: 'Modified' },
  { type: 'partial', img: partial, label: 'Partial' },
  { type: 'rejected', img: blocked, label: 'Blocked' },
]

export default {
  name: 'Table',
  data() {
    return {
      tableWrapperHeight: '100%',
      summaryBuilder,
      localOptions: {},
    }
  },
  watch: {
    '$vuetify.breakpoint.height': {
      handler: function (n) {
        this.tableWrapperHeight =
          this.$refs.table.$parent.$parent.$el.offsetHeight

        if (this.type === 'sessions history') this.tableWrapperHeight -= 100
        if (this.tableWrapperHeight < 174) this.tableWrapperHeight = 174
      },
      deep: true,
    },
  },
  props: {
    headers: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    search: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: {},
    },
    rowClickAction: {
      type: Function,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: -1,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    markedLinesArr: {
      type: Array,
      default: () => [],
    },
    addToMarkedLinesArr: {
      type: Function,
      default: () => null,
    },
    removeFromMarkedLinesArr: {
      type: Function,
      default: () => null,
    },
    setOpId: {
      type: Function,
      default: () => null,
    },
    setFileGuid: {
      type: Function,
      default: () => null,
    },
    noDataText: {
      type: String,
      default: '',
    },
  },
  methods: {
    checkboxClicked(logId) {
      //if the checkbox is checked
      if (this.markedLinesArr.includes(logId)) {
        this.removeFromMarkedLinesArr(logId)
      }
      //if the checkbox is not checked
      else this.addToMarkedLinesArr(logId)
    },
    optionsUpdated(n) {
      if (!n.sortBy.length) return
      if (this.type === 'sessions history' || this.type === 'logs table') return
      const sortByObj = {
        sortBy: n.sortBy[0],
        sortDesc: n.sortDesc[0],
      }
      sessionStorage.setItem(
        `${this.type}-table-headers`,
        JSON.stringify(sortByObj)
      )
    },
  },
  mounted() {
    this.tableWrapperHeight = this.$refs.table.$parent.$parent.$el.offsetHeight
    if (this.type === 'sessions history') this.tableWrapperHeight -= 100
    const res = sessionStorage.getItem(`${this.type}-table-headers`)
    if (res) {
      const { sortBy, sortDesc } = JSON.parse(res)
      this.localOptions = {
        sortBy: [sortBy],
        sortDesc: [sortDesc],
      }
    } else {
      this.localOptions = this.options
    }
  },
  components: { Button },
}
</script>

<style lang="scss">
.custom-table {
  //   .table-header-first {
  //     border-radius: 10px 0 0 0;
  //   }

  //   .table-header-last {
  //     border-radius: 0 10px 0 0;
  //   }

  .bg-table-heading {
    // background: #e6e6e6 !important;
    max-height: 40px !important;
    margin-bottom: 10px;
    // padding: 0 5px !important;
  }

  .table-header-last {
    padding-right: 20px !important;
  }

  thead {
    background: #e6e6e6 !important;
    border-radius: 10px 10px 0 0;
  }

  // tbody {
  //   height: 400px !important;
  //   overflow: auto;
  //   position: relative;
  // }

  // .v-data-table {
  //   border-radius: 10px !important;
  // }

  // .scrollable-table {
  //   overflow-y: auto;
  //   border-bottom: 25px solid #fff !important;
  // }

  .v-data-table-header th {
    white-space: nowrap;
    font-size: 1.12rem !important;
    // padding: 0 5px !important;
  }

  // .v-data-table td {
  //   padding: 0 5px !important;
  // }

  // .v-data-table .w-70 {
  //   width: 70px !important;
  // }

  // .theme--light.v-data-table {
  //   border-bottom: none;
  // }

  /* required to sticky header ONLY WORK IN CSS*/
  // .v-data-table /deep/ .sticky-header {
  //   position: sticky;
  // }
  // .v-data-table /deep/ .v-data-table__wrapper {
  //   overflow: unset;
  // }

  tbody {
    display: block;
    height: calc(100% - 65px);
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
  thead {
    width: calc(
      100% - 0px
    ); /* scrollbar is average 1em/16px width, remove it from thead width */
  }
  table {
    //   width: calc(100% - 8px);
    height: calc(100% - 55px);
  }

  td {
    word-break: break-word;
  }

  .w-52 {
    width: 52px;
  }
  .w-80 {
    width: 80px;
  }
  .light-blue-bg {
    background-color: rgba(34, 137, 221, 0.3);
  }
}
</style>
