<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="auto"
    tabindex="0"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <!--ROUTE INFORMATION-->
    <div class="bg-white title-border">
      <div id="dirFileReport">
        <!--TITLE-->
        <div class="card-title bg-grey">
          <span>{{ $t('Filtering Report') }}</span>

          <img
            :src="`https://www.${window.location.hostname}/api/images/${loggedUser.organizationId}/logo`"
            alt="host logo"
            class="h-35"
          />
        </div>

        <!--MAIN DATA-->
        <div class="main-data">
          <!--SELECTED FILES-->
          <div v-if="report.files.length" tabindex="0">
            <span class="folder-files-title">{{ $t('Files selected') }}:</span>
            <div class="wrapper-1 files-wrapper">
              <!--LOOP ON SELECTED FILES IN THE DIRECTORY-->
              <FileRow
                v-for="file of report.files"
                :key="file.historyId"
                :file="file"
                :level="0"
                tabindex="1"
              />
            </div>
          </div>

          <!--SELECTED DIRECTORIES-->
          <div v-if="report.directories.length" tabindex="0">
            <span class="folder-files-title"
              >{{ $t('Folders selected') }}:</span
            >

            <!--LOOP (RECURSIVELY) ON THE SELECTED DIRECTORIES-->
            <DirRow
              v-for="dir of report.directories"
              :key="dir.name"
              :dir="dir"
              :level="0"
              class="roots-dir-row"
            />
          </div>
        </div>
      </div>
      <v-divider />

      <!--BUTTONS ACTIONS-->
      <div class="d-flex justify-space-between">
        <!--DOWNLOAD REPORT-->
        <Button
        :clickAction="downloadReport"
        width="160"
        :text="$t('Download Report')"
        class="ma-2"
        />
        <!--CLOSE DIALOG-->
        <Button
          :clickAction="closeReportDialog"
          width="120"
          btnType="grey"
          :text="$t('Close')"
          class="ma-2"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { selectedHost } from '@/config'

//Base components
import Button from '@/components/BaseComponents/Button/Button.vue'

//Childrens
import DirRow from './Children/DirRow/DirRow.vue'
import FileRow from './Children/FileRow/FileRow.vue'
import { mapGetters } from 'vuex'
import { convertUtcToLocal } from '@/utils'

export default {
  name: 'DirFilesTableReportDialog',
  data() {
    return {
      dialog: true,
      selectedHost,
      window,
    }
  },
  props: {
    closeReportDialog: Function,
    report: Object,
  },
  components: { Button, FileRow, DirRow },
  computed: {
    ...mapGetters(['loggedUser', 'isDarkMode', 'themeSettings']),
  },
  methods: {
    async downloadReport() {
      const reportHTML = document.querySelector('#dirFileReport')
      const styles = require('!!raw-loader!./DirFilesTableReportDialog.css')
      const { name } = this.themeSettings
      const localDate = dayjs().format('YYYY-MM-DD HH-mm')

      // string of original file
      const fullHtml = `
      <!DOCTYPE html>
        <html lang="en" style="overflow-y: auto;">
          <head>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>Filtering Report</title>
          </head>

          <style>
            @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
              html {
                font-family: 'Roboto', sans-serif;
                }

              ${styles.default}
          </style>

          <body>${reportHTML.outerHTML}</body>
        </html>`

      const bottomLine = `
        <hr>
        <div style="padding: 5px; display: flex;justify-content: space-between; align-items: center;">
          <span>${localDate}</span>
          <div style="display: flex; align-items: center;">
            <h3 style="margin: 0 5px; font-weight: 400;">${
              name || 'dotEngines'
            }</h3>
          </div>
        </div></div></body>
            `

      const editedHtml = fullHtml.replace('</div></body>', bottomLine)

      //create blob, create a tag and download throw it the files
      const date = dayjs().format('DD-MM-YYYY HH-mm-ss')

      const blob = new Blob([editedHtml])
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)

      link.setAttribute(
        'download',
        `Filtering Report ${date
          .replaceAll('/', '.')
          .replaceAll(':', '-')}.html`
      )

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>

<style scoped src="./DirFilesTableReportDialog.css"></style>
