<template>
  <div class="d-flex mb-5">
    <div class="text-field-container">
      <!--TEXT AND RECOMMENDED SIZE-->
      <span
        class="small-title"
        :class="[status !== 0 && !isGlobal && 'low-op-disabeld']"
        >Logo
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small>mdi-information-outline</v-icon>
          </template>
          <span> Recommended: square .png file</span>
        </v-tooltip>
      </span>

      <!--SHOWN IMAGE-->
      <v-img
        ref="logoImage"
        :src="computedLogoUrl"
        alt="Header logo"
        class="shown-logo"
      />

      <!--SELECT LOGO-->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-hover v-slot="{ hover }">
            <v-icon
              :color="hover ? 'green' : 'grey'"
              v-on="on"
              class="my-2 mr-2"
              @click="logoFileInputClicked"
              :disabled="status !== 0 && !isGlobal"
              >mdi-upload</v-icon
            >
          </v-hover>
        </template>
        <span>Select logo</span>
      </v-tooltip>

      <!--DELETE LOGO-->
      <v-tooltip v-if="!isGlobal && actionType === 'edit'" bottom>
        <template v-slot:activator="{ on }">
          <v-hover v-slot="{ hover }">
            <v-icon
              :color="hover ? 'red' : 'grey'"
              v-on="on"
              class="my-2"
              @click="deleteLogo"
              :disabled="status !== 0 && !isGlobal"
              >mdi-delete</v-icon
            >
          </v-hover>
        </template>
        <span>Delete logo</span>
      </v-tooltip>
    </div>

    <!--CHECKBOXES-->
    <div v-if="!isGlobal" class="d-flex align-center">
      <v-checkbox
        v-model="logoShowInArray"
        value="Website"
        label="Website"
        :disabled="status !== 0"
      ></v-checkbox>
      <v-checkbox
        v-model="logoShowInArray"
        value="Email"
        label="Email"
        class="ml-4"
        :disabled="status !== 0"
      ></v-checkbox>
    </div>

    <!--THE INPUT TO ADD LOGO-->
    <input
      style="display: none"
      type="file"
      ref="avatar"
      dense
      accept="image/png, image/jpeg, image/bmp"
      @change="e => updateLogoPreview(e)"
    />
  </div>
</template>

<script>
import { api, selectedHost } from '@/config'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { checkboxChanged } from '../../ThemeSettings'
import { alertDialog, confirmDialog } from '@/utils'

export default {
  name: 'HeaderLogo',
  data() {
    return {
      logoShowInArray: [],
      logoUrl: null,
      readerResult: null,
    }
  },
  props: {
    organization: Object,
    status: Number,
    isGlobal: Boolean,
    actionType: String,
    images: Object,
  },
  watch: {
    logoShowInArray: function (n) {
      this.organization.theme.logoInterface = checkboxChanged(n)
    },
  },
  computed: {
    ...mapGetters(['currentOrganization', 'loggedUser', 'themeSettings']),
    computedLogoUrl() {
      if (this.readerResult) return this.readerResult
      const time = Date.now()
      return `${this.logoUrl}?${time}`
    },
  },
  methods: {
    ...mapMutations(['SET_NOTIFICATION']),
    ...mapActions(['GET_THEME']),
    logoFileInputClicked() {
      this.$refs.avatar.value = null
      this.$refs.avatar.click()
    },

    async updateLogoPreview(e) {
      const logo = e.target.files[0]
      if (!logo.type.includes('image/' || logo.type.includes('svg+xml'))) {
        return alertDialog(this, 'File type is not supported for logo')
      }
      const reader = new FileReader()
      reader.readAsDataURL(logo)

      //save the photo in varible and then add it
      this.images.logo = logo

      //i dont know why but the timeout is needed to show the logo
      setTimeout(() => {
        this.readerResult = reader.result
      }, 100)
    },

    deleteLogo() {
      this.logoUrl = `${selectedHost}/api/images/0/logo`
      this.images.logo = 'delete'
    },
  },
  mounted() {
    //if this is global organization
    if (this.isGlobal) {
      this.logoUrl = `${selectedHost}/api/images/0/logo`
    }
    //if this is regular organization
    else {
      this.logoUrl = `${selectedHost}/api/images/${this.currentOrganization.organizationId}/logo`
    }

    if (this.organization.theme.logoInterface === 1) {
      this.logoShowInArray.push('Website')
    } else if (this.organization.theme.logoInterface === 2) {
      this.logoShowInArray.push('Email')
    } else if (this.organization.theme.logoInterface === 3) {
      this.logoShowInArray.push('Website')
      this.logoShowInArray.push('Email')
    }
  },
}
</script>

<style scoped src="../ThemeSettingsChildren.css"></style>
