<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="auto !important"
    class="wrapper-1 bg-white pos-relative"
    :content-class="isDarkMode ? 'dark-mode' : ''"
  >
    <v-card class="wrapper-1">
      <v-card-title class="text-center bg-grey mb-2">
        Create new policy
      </v-card-title>
      <v-card-text class="px-2 pb-1">
        <v-text-field
          v-model="newPolicyName"
          lazy
          :rules="newPolicyRules"
          class="ma-auto"
          placeholder="New policy name"
          type="text"
          dense
        ></v-text-field>
      </v-card-text>
      <div class="d-flex justify-space-between pa-2">
        <Button
        :clickAction="createNewPolicyClicked"
        :disabled="isCreateRenameBtnsDisabled"
        width="fit-content"
        btnType="blue"
        class="mx-2"
        text="Create"
        />
        <Button
          :clickAction="closeDialog"
          width="fit-content"
          btnType="grey"
          text="Cancel"
          class="mx-2"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from '@/components/BaseComponents/Button/Button.vue'
import { isFolderNameValid } from '@/utils'
import { mapGetters } from 'vuex'
import { newPolicyRules } from '../../../RenameDialog/RenameDialog'

export default {
  name: 'NewPolicyNameDialog',
  data() {
    return {
      newPolicyName: '',
      dialog: true,
    }
  },
  props: {
    closeDialog: Function,
    createNewPolicy: Function,
  },
  components: { Button },

  computed: {
    ...mapGetters(['policies', 'isDarkMode']),
    newPolicyRules,
    isCreateRenameBtnsDisabled() {
      if (!this.newPolicyName) return true
      else if (!isFolderNameValid(this.newPolicyName)) return true
      else if (this.policies.includes(this.newPolicyName)) return true
      return false
    },
  },
  methods: {
    createNewPolicyClicked() {
      this.createNewPolicy(this.newPolicyName)
      this.closeDialog()
    },
  },
}
</script>
